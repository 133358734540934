import { Notification, PlanSubscription, Users, Clients } from "./context";
import "./App.css";
import "./assets/css/main.css";
import Auth from "./auth";
import React, { useState } from "react";
import { createTheme, CssBaseline, Grow, ThemeProvider } from "@mui/material";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import { ToastContainer, Zoom } from "react-toastify";
import "src/assets/css/new-static-calendar.css"; // custom css for full calendar
import "src/assets/css/custom-quill.css"; // custom css for full calendar

import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";
import Constant from "./utils/Constant";
import { SnackbarContextProvider } from "./context/SnackbarContext";
import { IntegrationsContextProvider } from "./context/IntegrationsContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

/** import all the integration resource */
import MailchimpResource from "src/views/coach/integrations/v2/mailchimp/Resource";
import AweberResource from "src/views/coach/integrations/v2/aweber/Resource";
import ActiveCampaignResource from "src/views/coach/integrations/v2/active-campaign/Resource";
import ConvertkitResource from "src/views/coach/integrations/v2/convertkit/Resource";
import GetresponseResource from "src/views/coach/integrations/v2/getresponse/Resource";
import MailerLiteResource from "src/views/coach/integrations/v2/mailerlite/Resource";

import { Main } from "./Main";
import ClientAuth from "./auth/client";
import ClientPortalRoutes from "./routes/client-portal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      light: "#000",
      dark: "#000",
    },
  },
  typography: {
    fontFamily: "DM Sans",
    allVariants: {
      color: "#060A2D",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "inherit",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none", // Optional: to prevent uppercase transformation of tab labels
          fontWeight: "bold", // Optional: to make the tab label bold
          "&.Mui-selected": {
            color: "#D97855", // Change this to your preferred active tab color
            borderBottom: "2px solid #D97855", // Add bottom border for active tab
          },
          // Optional: To ensure border-bottom appears correctly
          "&:not(.Mui-selected)": {
            borderBottom: "2px solid transparent", // To hide the border for non-selected tabs
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none", // Hide the default indicator
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,

      tcal: 1200, //custom treffas calendar size
      tbill: 922, //custom treffas billing size
      tc: 926, //custom treffas container size
      tp: 768, //custom treffas page size
      bp: 918, //custom treffas  booking page size,
      cplc: 678, //coach packages list container
      caf: 680, // create appointment form,
      ntc: 840, // new treffas container,
      invoice_container: 960,
      posts_container: 940,
      editorjs_container: 884,
      invoice_form: 880,
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [planSubscription, setPlanSubscription] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [userIntegrations, setUserIntegrationList] = useState([]);
  const notistackRef = React.createRef();
  const list = new Main();
  const __loginType = localStorage.getItem("__loginType") ?? "coach";

  const [client, setClient] = useState(null);

  // register integration resources in app
  list.register({
    mailchimp: MailchimpResource,
    aweber: AweberResource,
    active_campaign: ActiveCampaignResource,
    convertkit: ConvertkitResource,
    getresponse: GetresponseResource,
    mailerlite: MailerLiteResource,
  });

  React.useEffect(() => {
    mixpanel.init(Constant.mixPanelKey);

    // generateIntegrations();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        // autoHideDuration={1200}
        maxSnack={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionComponent={Grow}
        ref={notistackRef}
        iconVariant={{
          success: <CheckCircleOutlineIcon sx={{ marginRight: "12px" }} />,
          error: <HighlightOffIcon sx={{ marginRight: "12px" }} />,
        }}
        classes={{
          variantSuccess: "custom-snackbar-success",
        }}
      >
        <SnackbarContextProvider>
          <MixpanelProvider mixpanel={mixpanel}>
            {__loginType === "client" ? (
              /* __loginType is client */
              <Clients.Provider
                value={{
                  client: client,
                  updateClient: setClient,
                  isLogin: isLogin,
                  setIsLogin: (status) => setIsLogin(status),
                  mixpanel: mixpanel,
                }}
              >
                <ClientAuth>
                  <ClientPortalRoutes />
                </ClientAuth>
              </Clients.Provider>
            ) : (
              // / __loginType is default coach/user
              <Users.Provider
                value={{
                  user: user,
                  updateUser: setUser,
                  isLogin: isLogin,
                  setIsLogin: (status) => setIsLogin(status),
                  mixpanel: mixpanel,
                  setUserIntegrationList,
                  userIntegrations,
                }}
              >
                <PlanSubscription.Provider
                  value={{
                    planSubscription: planSubscription,
                    setPlanSubscription: setPlanSubscription,
                  }}
                >
                  <Auth>
                    <Notification.Provider
                      value={{
                        notifications: notifications,
                        setNotifications: setNotifications,
                      }}
                    >
                      <IntegrationsContextProvider integrationResource={list}>
                        <Routes />
                      </IntegrationsContextProvider>
                    </Notification.Provider>
                  </Auth>
                </PlanSubscription.Provider>
              </Users.Provider>
            )}
          </MixpanelProvider>
        </SnackbarContextProvider>
      </SnackbarProvider>

      {/* toaster */}
      <ToastContainer
        position="top-center"
        autoClose={1200}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName={"snackbar-customize"}
        transition={Zoom}
      />
    </ThemeProvider>
  );
}

export default App;
